@import 'assets/scss/variables.module';

.container {
  @include flex-center;
  flex-direction: column;
  margin: 0 !important;
  gap: 20px;

  padding-bottom: 100px;
}

.containWidth {
  width: 1725px;
  max-width: 90%;
  margin-bottom: 50px;

  @include response-x-large {
    width: 1345px;
  }

  @include response-large {
    width: 1025px;
  }

  @include response-medium {
    width: 90%;
  }
}
